<template>
    <div class="clue">
        <div class="clue_title">
            <span>
                <i class="shu"></i>
                线索信息
            </span>
        </div>
        <div class="form">
            <div style="flex: 1">
                <el-form
                    label-width="90px"
                    label-position="left"
                    size="small"
                    style="padding: 16px 35px"
                >
                    <el-form-item label="线索产品" required>
                        <el-cascader
                            size="small"
                            ref="cascader"
                            v-model="form.productClue"
                            :options="productListData"
                            :style="{ width: '80%' }"
                            :show-all-levels="false"
                            clearable
                            placeholder="请选择线索产品"
                            @change="
                                (val) => {
                                    form.launchId = '';
                                    getLaunchList(val);
                                }
                            "
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                value: 'id',
                                label: 'productName',
                                children: 'children',
                            }"
                        >
                        </el-cascader>
                    </el-form-item>

                    <el-form-item label="投放站点">
                        <el-select
                            v-model="form.launchId"
                            style="width: 80%"
                            placeholder="请选择投放站点"
                            clearable
                        >
                            <el-option
                                v-for="(item, index) in launchList"
                                :key="item.id"
                                :label="item.account"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="线索类型" required>
                        <el-select
                            v-model="form.clueType"
                            size="small"
                            style="width: 80%"
                            clearable
                            placeholder="线索类型"
                        >
                            <el-option label="售前咨询" :value="1"></el-option>
                            <el-option label="购买咨询" :value="2"></el-option>
                            <el-option label="售后" :value="3"></el-option>
                            <el-option
                                label="基础版注册"
                                :value="4"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="客户名称">
                        <el-input
                            v-model="form.companyName"
                            placeholder="请输入客户名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="域名">
                        <el-input
                            v-model="form.companyDomain"
                            placeholder="请输入域名"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="关联域名">
                        <div
                            class="info contact_way_box"
                            :key="index"
                            v-for="(item, index) in form.lineDomainDOList"
                        >
                            <el-input
                                v-model="item.domain"
                                placeholder="请输入关联域名"
                                size="small"
                            ></el-input>
                            <el-button
                                v-if="index == 0"
                                @click="addDomain"
                                type="primary"
                                class="el-icon-plus"
                                circle
                                size="mini"
                            ></el-button>
                            <el-button
                                v-if="index > 0"
                                @click.prevent="removeDomain(index)"
                                class="el-icon-minus"
                                type="primary"
                                circle
                                size="mini"
                            ></el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="预计用户数">
                        <el-select
                            size="small"
                            v-model="form.estimateNumber"
                            placeholder="请选择预计用户数"
                            style="width: 80%"
                            clearable
                        >
                            <el-option
                                v-for="item in $MailStatus.estimateNumber()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计年限">
                        <el-select
                            size="small"
                            v-model="form.estimateTerm"
                            placeholder="请选择预计年限"
                            style="width: 80%"
                            clearable
                        >
                            <el-option
                                v-for="item in $MailStatus.estimateTerm()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="原邮箱品牌">
                        <el-select
                            v-model="form.primaryEmailBrand"
                            size="small"
                            style="width: 80%"
                            clearable
                            placeholder="请选择原邮箱品牌"
                        >
                            <el-option
                                v-for="item in $searchForm.mailboxbrand()"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属行业">
                        <el-select
                            v-model="form.business"
                            size="small"
                            style="width: 80%"
                            clearable
                            placeholder="请选择所属行业"
                        >
                            <el-option
                                v-for="item in $industry.industry()"
                                :label="item.label"
                                :value="item.value"
                                :key="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所在地">
                        <el-cascader
                            size="small"
                            style="width: 80%"
                            clearable
                            :options="$address.cascader()"
                            v-model="addressArr"
                            placeholder="请选择所在地"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="联系备注">
                        <el-input
                            v-model="form.contactRemark"
                            placeholder="请输入联系备注"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="flex: 1">
                <el-form
                    label-width="90px"
                    label-position="left"
                    size="small"
                    style="padding: 16px 35px"
                >
                    <el-form-item label="需求" required>
                        <el-input
                            style="width: 80%"
                            type="textarea"
                            :rows="3"
                            placeholder="请输入需求"
                            v-model="form.remark"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="留资方式" required>
                        <el-select
                            v-model="form.retentionMethod"
                            size="small"
                            style="width: 80%"
                            clearable
                            placeholder="留资方式"
                        >
                            <el-option label="添加微信" :value="1"></el-option>
                            <el-option label="400电话" :value="2"></el-option>
                            <el-option label="在线留言" :value="3"></el-option>
                            <el-option label="官网表单" :value="4"></el-option>
                            <el-option label="邮件" :value="5"></el-option>
                            <el-option label="自拓" :value="6"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户类型">
                        <el-radio v-model="form.companyType" :label="1"
                            >企业</el-radio
                        >
                        <el-radio v-model="form.companyType" :label="2"
                            >个人</el-radio
                        >
                    </el-form-item>
                    <el-form-item label="客户标签">
                        <span
                            :key="index"
                            :class="item.category == 0 ? 'blue' : 'yellow'"
                            class="labelList"
                            v-for="(item, index) in $choiceLabel.state
                                .labelList"
                            >{{ item.tagName }}
                            <span class="cancel" @click="onDeleteLabel(item)">
                                <span class="el-icon-close"></span>
                            </span>
                        </span>
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="small"
                            @click="onChoiceLabel"
                            style="
                                background: rgba(0, 0, 0, 0.02);
                                border-color: rgba(0, 0, 0, 0.15);
                                color: #000000;
                                padding: 9px;
                            "
                        ></el-button>
                    </el-form-item>
                    <el-form-item label="联系方式">
                        <div
                            class="info contact_way_box"
                            v-for="(item, index) in form.lineContactDOList"
                            :key="index"
                            style="width: 100%"
                        >
                            <el-select
                                v-model="item.contactWay"
                                size="small"
                                clearable
                                placeholder="方式"
                            >
                                <el-option label="电话" :value="1"></el-option>
                                <el-option label="微信" :value="2"></el-option>
                                <el-option label="邮箱" :value="3"></el-option>
                                <el-option label="QQ" :value="4"></el-option>
                                <el-option label="短信" :value="5"></el-option>
                                <el-option label="拜访" :value="6"></el-option>
                                <!--<el-option label="官网表单" :value="8"></el-option> -->
                                <el-option label="座机" :value="9"></el-option>
                                <el-option label="其他" :value="7"></el-option>
                            </el-select>
                            <el-input
                                style="margin-left: 5px"
                                v-model="item.contactContent"
                                placeholder="号码"
                                size="small"
                            ></el-input>
                            <el-input
                                style="margin-left: 5px"
                                v-model="item.contactName"
                                placeholder="联系人"
                                size="small"
                            ></el-input>
                            <el-button
                                v-if="index == 0"
                                @click="addContact"
                                type="primary"
                                class="el-icon-plus"
                                circle
                                size="mini"
                            ></el-button>
                            <el-button
                                v-if="index > 0"
                                @click.prevent="removeContact(index)"
                                class="el-icon-minus"
                                type="primary"
                                circle
                                size="mini"
                            ></el-button></div
                    ></el-form-item>
                    <el-form-item label="到期时间">
                        <el-date-picker
                            v-model="form.companyExpireTime"
                            size="small"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width:80%;x"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-input
                            v-model="form.address"
                            placeholder="请输入地址"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            v-model="form.lineRemark"
                            placeholder="请输入备注"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                :isGet="isGet"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
    </div>
</template>
<script>
import { launchList } from '@/api/user/launch/manage.js';
import ChoiceLabel from '../../../../../components/choice-label/choiceLabel.vue';
import AddLable from '../../../../../components/choice-label/addLabel.vue';
import { productList } from '@/api/product/product.js';
export default {
    components: {
        ChoiceLabel,
        AddLable,
    },
    data() {
        return {
            form: {
                lineDomainDOList: [{}],
                lineContactDOList: [
                    {
                        contactWay: 1,
                        contactContent: '',
                        contactName: '',
                    },
                ],
                companyType: 1,
                launchId: '',
            },
            addressArr: [],
            launchList: [],
            productListData: [],
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            btnP: {},
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = [];
            let arr = [];
            let btnP = {};
            for (let i in btnArr) {
                if (btnArr[i].route == 'add') {
                    arr = btnArr[i];
                    break;
                }
            }
            btn = arr.children;
            for (let i in btn) {
                btnP['' + btn[i].route] = true;
            }
            if (btnP.market && !btnP.sale) {
                this.form.sourceCategory = 1;
            }
            if (!btnP.market && btnP.sale) {
                this.form.sourceCategory = 2;
            }
            let data = {
                param: {
                    status: 1,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data).then((res) => {
                this.productListData = this.$Tree.treeDataTranslate(
                    res.data.list,
                    'id',
                    'parentId',
                    false
                );
            });
            this.btnP = btnP;
            // this.getLaunchList();
        },
        // 投放列表
        getLaunchList(productId) {
            let data = {
                param: { status: 1 },
                pageNum: 0,
                pageSize: 0,
            };
            if (productId) {
                data.param.productId = productId;
            }
            launchList(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.launchList = res.data.list;
                }
            });
        },
        // 添加域名
        addDomain() {
            this.form.lineDomainDOList.push({});
        },
        // 删除域名
        removeDomain(index) {
            this.form.lineDomainDOList.splice(index, 1);
        },
        // 添加联系方式
        addContact() {
            this.form.lineContactDOList.push({
                contactWay: 1,
                contactContent: '',
                contactName: '',
            });
        },
        // 删除联系方式
        removeContact(index) {
            this.form.lineContactDOList.splice(index, 1);
        }, // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 选择标签
        onChoiceLabel() {
            // console.log(this.$choiceLabel.state.labelList);
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.isGet = !that.isGet;
                this.$choiceLabel.commit('isMultiple', false);
                this.$choiceLabel.commit('getType', '2');
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
        },
        handleClose() {
            this.dialogChoice = false;
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
    },
};
</script>
<style lang="scss" scoped>
.clue {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.clue_title {
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #eeeeee;

    font-size: 14px;
    span {
        display: flex;
        .shu {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #2370eb;
            margin-right: 22px;
        }
    }
}
.form {
    display: flex;
    .contact_way_box {
        background-color: #fff;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 0;
        margin-bottom: 10px;
        position: relative;
        button {
            border: 2px solid #2370eb;
            background: #fff;
            color: #2370eb;

            padding: 2px;
            margin-left: 8px;
        }
    }
    .el-input {
        width: 80%;
    }
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
</style>
