<template>
    <div class="addClue">
        <div class="title">
            <p>
                <i class="el-icon-plus" style="color:#2370EB"></i>
                添加线索
            </p>
            <p>
                <el-button
                    @click="onClose()"
                    size="mini"
                    type="primary"
                    icon="el-icon-arrow-left"
                    style="background:#fff;color:#2370EB;border-color:#2370EB"
                    >返回列表</el-button
                >
            </p>
        </div>
        <div class="content_box">
            <div class="clue">
                <Clue ref="clue" />
                <div class="bottom-but">
                    <span>
                        <el-button
                            round
                            type="primary"
                            size="mini"
                            style="background:none;border-color:#D5D6DF;color:#666666;"
                            @click="onClose"
                            >取消</el-button
                        >
                        <el-button
                            round
                            type="primary"
                            style="background:#2370EB;border-color:#2370EB;color:#fff"
                            size="mini"
                            @click="onSubmit"
                            >保存</el-button
                        ></span
                    >
                </div>
            </div>
            <div class="dealings">
                <Dealings ref="dealings" />
            </div>
        </div>
    </div>
</template>
<script>
import Clue from './clue/clue.vue';
import Dealings from './dealings/dealings.vue';
import { clueAdd } from '@/api/clue/meClue';
export default {
    components: {
        Clue,
        Dealings,
    },
    data() {
        return {};
    },
    methods: {
        onSubmit() {
            let clue = this.$refs.clue.form;
            let dealings = this.$refs.dealings.lineIntercourseList;
            console.log(clue);
            if (!clue.productClue) {
                return this.$message.error('请选择线索产品');
            } else if (!clue.remark) {
                return this.$message.error('请输入需求');
            } else if (!clue.clueType) {
                return this.$message.error('请选择线索类型');
            } else if (!clue.retentionMethod) {
                return this.$message.error('请选择留资方式');
            }

            let data = {
                param: clue,
            };
            data.param.lineTagList = this.$choiceLabel.state.labelList;
            data.param.lineIntercourseList = [dealings];
            data.param.provinceId = this.$refs.clue.addressArr[0];
            data.param.cityId = this.$refs.clue.addressArr[1];
            for (let i in data.param.lineDomainDOList) {
                if (data.param.lineDomainDOList[i].domain == '') {
                    data.param.lineDomainDOList.splice(i, 1);
                }
            }
            for (let i in data.param.lineContactDOList) {
                if (
                    !data.param.lineContactDOList[i].contactContent ||
                    data.param.lineContactDOList[i].contactContent == ''
                ) {
                    data.param.lineContactDOList.splice(i, 1);
                }
            }
            data.param.sourceCategory = 1;
            clueAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$router.go(-1);
            this.$choiceLabel.commit('emptyData');
        },
    },
};
</script>
<style lang="scss" scoped>
.addClue {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
        height: 56px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
    }
    .content_box {
        margin-top: 12px;
        flex: 1;
        display: flex;
        min-height: 0;
        .clue {
            min-height: 0;
            flex: 1.5;
            background: #fff;
            margin-right: 12px;
            display: flex;
            flex-direction: column;
        }
        .dealings {
            min-height: 0;
            flex: 1;
            background: #fff;
        }
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
        span {
            margin-left: auto;
            margin-right: 20px;
        }
        button {
            width: 90px;
        }
    }
    /deep/ .el-textarea__inner {
        resize: none;
    }
}
</style>
